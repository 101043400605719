import { Box, Button, Typography } from "@mui/material"
import type { FC } from "react";
import MenuIcon from '@mui/icons-material/Menu';

const TopBar: FC<{ onToggleMenu: () => void }> = ({ onToggleMenu }) => {
  return <Box sx={{
    position: 'relative',
    maxHeight: 56,
    minHeight: 56,
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 5,
    px: 2,
    borderRadius: 1
  }}>
    <Button onClick={onToggleMenu}
      sx={t => ({
        borderRadius: 40,
        width: 40,
        minWidth: 40,
        height: 40,
      })}><MenuIcon sx={{ fontSize: 32 }} color={"action"} /></Button>
  </Box>
}

export default TopBar;
