import { Box, CircularProgress } from "@mui/material";
import type { FC } from "react";

const ProgressSkeleton: FC = () => {
  return <Box sx={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, }}>
    <CircularProgress sx={t => ({ color: t.palette.primary.dark })} />
  </Box>
}

export default ProgressSkeleton;
