import { Avatar, Button, ListItem, Menu, MenuItem, Typography } from '@mui/material';
import { useAnchorMenu } from 'hooks';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

const UserNav = () => {
  const { isOpen, anchor, handleOpenMenu, handleCloseMenu } = useAnchorMenu();
  const { user } = useAuthenticator((context) => [context.user]);
  const email = user.getSignInUserSession()?.getIdToken().payload.email as string;
  const acronymName = email ? `${email.split('.')[0].slice(0, 1)}${email.split('.')[1].slice(0, 1)}` : '';

  const handleSignOut = async () => {
    await Auth.signOut();
  };

  return (
    <>
      <Button
        sx={(t) => ({
          color: t.palette.text.secondary,
          borderRadius: t.borderRadius.sm,
        })}
        id="user-nav-button"
        aria-controls={isOpen ? 'user-nav' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        variant="text"
        disableElevation
        disableRipple
        disableTouchRipple
        onClick={handleOpenMenu}
      >
        <Avatar
          sx={t => ({
            bgcolor: 'white',
            fontSize: 14,
            lineHeight: 24,
            color: 'black',
            textTransform: 'uppercase',
            '&': {
              border: `1px solid ${t.palette.grey[200]}`,
            }
          })}>
          {acronymName}
        </Avatar>
      </Button>
      <Menu
        id="user-nav"
        slotProps={{
          paper: {
            sx: {
              width: 200,
            },
          },
        }}
        elevation={8}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchor}
        open={isOpen}
        onClose={handleCloseMenu}
      >
        <ListItem divider>
          <Typography sx={t => ({ fontSize: t.typography.body2.fontSize, color: t.palette.grey[600], textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', })}>
            {email}
          </Typography>
        </ListItem>
        <MenuItem onClick={handleSignOut}>
          <Typography sx={t => ({ fontSize: t.typography.body2.fontSize })}>Log out</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserNav;
