import type { FC } from 'react';
import React, { Suspense } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import config from 'config';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Titled } from 'react-titled';
import { SnackbarProvider } from 'notistack';
import queryString from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GlobalErrorBoundary from 'GlobalErrorBoundary';
import { queryClient } from 'lib/react-query';
import AuthRequired from 'lib/auth/AuthRequired';
import ProgressSkeleton from 'components/layout/ProgressSkeleton';
import Login from 'pages/login';
import { Layout } from 'components/layout';
import theme from './theme';
import RulesPage from 'pages/rules';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: config.COGNITO_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN ?? `${window.location.origin}/`,
      redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT ?? `${window.location.origin}/`,
      responseType: 'code',
    },
  },
});

const Providers: FC = () => (
  <QueryParamProvider
    adapter={ReactRouter6Adapter}
    options={{
      searchStringToObject: (str) => queryString.parse(str, { arrayFormat: 'comma' }),
      objectToSearchString: (input) => queryString.stringify(input, { arrayFormat: 'comma' }),
    }}
  >
    <CssBaseline />
    <Authenticator.Provider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalErrorBoundary>
            <SnackbarProvider
              autoHideDuration={1000}
              maxSnack={5}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              preventDuplicate
            >
              <Suspense fallback={<ProgressSkeleton />}>
                <Outlet />
              </Suspense>
            </SnackbarProvider>
          </GlobalErrorBoundary>
        </ThemeProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Authenticator.Provider>
  </QueryParamProvider>
);

const router = createBrowserRouter([
  {
    element: (
      <Titled title="AutoRules">
        <Providers />
      </Titled>
    ),
    children: [
      {
        index: true,
        path: '/rules',
        element: (
          <AuthRequired>
            <Titled title="Rules">
              <Layout>
                <RulesPage />
              </Layout>
            </Titled>
          </AuthRequired>
        ),
      },
    ],
  },
  {
    path: '/login',
    element: (
      <Titled title="Login">
        <Login />
      </Titled>
    ),
  },
  { path: '*', element: <Navigate to="/rules" replace /> },
]);

const App: FC = () => (
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

export default App;
