import { Box, CssBaseline, LinearProgress } from '@mui/material';
import LoginForm from './LoginForm';
import useCheckSession from 'hooks/useCheckSession';
import { GradientBox, TitleStyled } from './LoginComponents';

const Login = () => {
  const { isChecked } = useCheckSession();

  return isChecked ? (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', height: '100vh', minWidth: 320 }}>
        <GradientBox>
          <TitleStyled>AutoRules</TitleStyled>
        </GradientBox>
        <LoginForm />
      </Box>
    </>
  ) : (
    <LinearProgress />
  );
};

export default Login;
