import { CssBaseline, Box } from '@mui/material';
import type { FC, ReactNode } from 'react';
import Sidebar from './Sidebar';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Box
        sx={t => ({
          display: 'flex',
          flexGrow: 1,
          width: '100%',
          height: '100vh',
          position: 'relative',
          zIndex: 1,
          gap: 0.75,
          p: 0.75,
          background: 'linear-gradient(113.18deg, #D1DDFD 0.62%, #D6CEF4 100.46%)',
          [t.breakpoints.down('sm')]: {
            flexDirection: 'column'
          },
        })}
      >
        <Sidebar />
        <Box
          sx={t => ({
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            pb: 3,
            px: 3,
            overflow: 'hidden',
            overflowY: 'auto',
            backgroundColor: 'white',
            borderRadius: 1,
            [t.breakpoints.up('sm')]: {
              pt: 4,
            },
            [t.breakpoints.down('sm')]: {
              pt: 3,
            },
          })}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
export default Layout;
