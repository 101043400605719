import { Box, Typography } from "@mui/material";
import { FC } from "react";

const Rules: FC = () => {
  return (
    <Box sx={{ display: 'flex', flexGrow: 1, py: 1, flexDirection: 'column', }}>
      <Typography>Rules page</Typography>
    </Box>
  );
};

export default Rules;
