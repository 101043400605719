import { Box, Typography, styled } from "@mui/material";

export const GradientBox = styled(Box)`
  padding: 16px 24px;
  background: linear-gradient(113.18deg, #D1DDFD 0.62%, #D6CEF4 100.46%);
  background-size: cover;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    justify-content: end  ;
    max-width: 450px;
  }

  ${({ theme: t }) => t.breakpoints.down('md')} {
    justify-content: flex-start;
  }
`;

export const TitleStyled = styled(Typography)`
  font-size: 48px;
  line-height: 1.25;
  color: #222;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    width: min-content;
  }
`;

export const FormBox = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    padding: 16px 40px;
  }

  ${({ theme: t }) => t.breakpoints.down('md')} {
    position: absolute;
    bottom: 24px;
    left: 24px;
    margin-right: 24px;
    max-width: 600px;
    padding: 40px 20px;
    border-radius: 12px;
    background-color: rgba(255,255,255,0.8)
  }
`;
