
import { Suspense, type FC } from "react";
import { Titled } from "react-titled";
import { Box } from '@mui/material';
import ProgressSkeleton from "components/layout/ProgressSkeleton";
import TitlePageStyles from 'components/common/TitlePageStyles';
import { Rules } from "features/rules";

const RulesPage: FC = () => {
  return (
    <>
      <Titled title={(title) => `AutoRules | ${title}`} />
      <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, }}>
        <TitlePageStyles>Rules</TitlePageStyles>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, }}>
        <Suspense fallback={<ProgressSkeleton />}>
          <Rules />
        </Suspense>
      </Box>
    </>
  );
};

export default RulesPage;
