import type { Theme } from "@mui/material";
import { Typography, styled } from "@mui/material";

const TitlePageStyles = styled(Typography)(({ theme: t }: { theme: Theme }) => (`
    line-height: 36px;
    ${t.breakpoints.up('sm')}{
      font-size: ${t.typography.h1.fontSize}
    }
    ${t.breakpoints.down('sm')}{
      font-size: ${t.typography.h2.fontSize}
    }
`));

export default TitlePageStyles;
