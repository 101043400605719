/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Auth } from 'aws-amplify';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

const useCheckSession = () => {
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from ? location.state?.from?.pathname + location.state?.from?.search : '/projects';

  const checkSession = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      if (session.isValid()) {
        navigate(from, { replace: true });
      } else {
        setIsChecked(true);
      }
    } catch (err) {
      setIsChecked(true);
    }
  }, [navigate, setIsChecked, from]);

  useLayoutEffect(() => {
    checkSession().catch(() => {
      // error catch
    });
  }, [checkSession]);

  return { isChecked };
};

export default useCheckSession;
